/*
 * @Author: Onein
 * @Date: 2019-08-22 18:19:28
 * @Last Modified by: Onein
 * @Last Modified time: 2019-08-31 17:52:10
 */
export default [
    {
        id: '@audit-log'
        // reducer: () => import('./audit-reducer'),
    },
    {
        path: '/audit-log',
        ref: '/',
        onload: function () { return import('./audit-log-list-view'); }
    },
    {
        point: '@@menus',
        onload: function () { return [
            {
                id: 'audit-log',
                pId: 'financial',
                permissions: ['AUDIT_ADMIN'],
                weight: 99,
                label: i18n.get('审计日志'),
                href: '/audit-log'
            }
        ]; }
    }
];
